#NFT_maindiv {
    width: 100vw;
    background-image: url('FAQ_background4.jpg');
    background-size: 130% 130%;
    background-position: center;
    
}
#NFT_container {
    font-family: 'prompt',sans-serif;
    font-size: 10vw;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: center;
}
#NFT_inner1{
    width: min(90vw,1100px);
    
    display: flex;
    flex-wrap: wrap;
}
#bullmoon_banner_logo2 {
    font-family: Prompt,sans-serif;
    font-weight: 800;
    font-size:max(30px,4vw);
    line-height: max(50px,1vw);
    letter-spacing: 0.2vw;
    right: 0px;
    top : min(11vw,40px);
    width: 100%;
    text-align: center;
    z-index: 10000;
}
#NFT_container_header {
    min-width: 100vw;
    margin-top: 10vh;
    filter: drop-shadow(0px 0px 5vw blue);
}
.NFT_item{
    min-width: 150px;
    max-width: 33%;
    padding: 0px;
    border-radius: 0px;
}
.NFT_circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-family: 'Prompt',sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: black;
    border-radius: 100%;
    background-color: yellow;
    padding: 0vw;
    width: 5vw;
    height: 5vw;
}
.NFT_circle_bigtext{
    font-size: 20px;
}

/**********************************************/
@media only screen and (max-width: 1000px) {
    #NFT_maindiv {
        background-size: cover;
        background-position: left;
        background-image: none;
        background-color: black ;
    }
    #NFT_container {
        font-size: 15vw;
        width: 100vw;
        justify-content: center;
    }
    #NFT_inner1{
        width: min(90vw,1500px);
        
        display: flex;
        flex-wrap: wrap;
    }
    #NFT_container_header {
        margin-top: 10vh;
    }
    .NFT_item{
        min-width: 150px;
        max-width: 50%;
        padding: 5px;
        border-radius: 15px;
    }
    #bullmoon_banner_logo2 {
        font-family: Prompt,sans-serif;
        font-weight: 800;
        font-size: 12vw;
        line-height: 11vw;
    }
}