
#bullmoon_banner3_container{
    background-image: url('bg2.jpg');

    /* Full height */
    width: 100%;
    background-repeat: repeat-x;
    background-size:cover;
    background-attachment: fixed;
    
}
#banner3_table {
    
    min-width: min(1600px,65vw);
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0, 0, 0, 0.95) 22%, rgba(35, 28, 62, 0.95) 75%, rgba(0,212,255,0) 100%);
    
}

.banner3_td_head {
    width: 100%;
    height: 20vh;
    vertical-align: bottom;
    font-family: 'Prompt', sans-serif;
    font-size: max(3.5vw,26px);
    line-height: max(3.8vw,30px);
    padding: 8px;
    justify-content: center;
    text-align: center;
}
.banner3_td1 {
    width: 35%;
    
    font-family: 'Prompt', sans-serif;
    font-size: min(1.2vw,22px);
    padding: 1vh;
    padding-left: 300px;
    font-weight: 500;
}
.banner3_td2  {
    filter: drop-shadow(0px 0px 1vw rgb(255, 255, 255));
    width: 65%;
    font-family: 'Prompt', sans-serif;
    font-size: min(1.2vw,22px);
    padding: 1vh;
    font-weight: 300;
}

@media only screen and (max-width: 1000px) {
    #bullmoon_banner3_container{
        background-image: url('bg2_mobile.jpg');
        background-size: cover;
        background-repeat: repeat;
        background-position-x: right;
        background-attachment: scroll;
    }
    #banner3_table {
        min-width: 100%;
        background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(4, 89, 117,.85) 10%, rgba(83, 28, 101,.7) 85%, rgba(0,212,255,0) 100%);
        padding-top: 290px;
        padding-bottom: 291px;
    }
    .banner3_td_head {
        width: 100%;
        font-size: 7.5vw;
        height: 20vw;
        font-weight: 600;
        padding: 8px;
        vertical-align:bottom ;
    }
    .banner3_td1 {
        width: 34%;
        font-size: 3.6vw;
        padding: 10px;
        padding-bottom: 0px;
        padding-left: 6vw;
        font-weight: 500;
    }
    .banner3_td2  {
        width: 66%;
        font-size: 3.6vw;
        line-height: 4.2vw;
        padding-right: 4vw;
        padding-top: 0px;
        padding-bottom: 1px;
        text-indent:-.5em;
        font-weight: 300;
    }
}