
#bullmoon_banner4_container{
    background-image: url('bg3.jpg');
    /* Full height */
    width: 100vw;
    background-repeat: repeat-x;
    background-size:cover;
    background-attachment: fixed;
}
#bullmoon_banner4_maindiv {
    width: 60%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 1vw;
    padding-right: 1vw;
    filter: drop-shadow(0px 0px 50px rgb(255, 230, 6));
}
.banner4_img {
    min-width: 150px;
    max-width: 10%;
}
#bullmoon_banner4_text{
    font-family: 'Prompt',sans-serif;
    font-size: 6vw;
    font-weight: 200;
    padding-top: 15vh;
    padding-bottom: 10vh;
    filter: drop-shadow(0px 0px 25px rgb(255, 230, 6));
}

@media only screen and (max-width: 1000px) {
    #bullmoon_banner4_container{
        background-image: url('bg3_mobile.jpg');
        background-size: cover;
        background-repeat: repeat;
        background-position-x: center;
        background-position-y: bottom;
        background-attachment: scroll;
    }
    .banner4_img {
        min-width: 0px;
        max-width: 22%;
    }
    #bullmoon_banner4_text {
        font-size: 45px;
        padding-top: 20vh;
        filter: drop-shadow(0px 0px 10px rgb(255, 230, 6));
    }
    #bullmoon_banner4_maindiv {
        width: 100%;
        padding-left: 2vw;
        padding-right: 2vw;
        filter: drop-shadow(0px 0px 10px rgb(255, 230, 6));
    }
}