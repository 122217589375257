#linkmint1 , #linkmint2 {
    /* display: none; */
}
#help_div {
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */

    font-family: 'prompt';
    width: 100%;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: bold;
    color: white;
    /*filter:drop-shadow(0px 0px 20px rgb(133, 11, 11));*/
}
#help_button{
    /* filter: drop-shadow(0 0 0 30px black); */
    color: rgb(255, 255, 255);
    filter:drop-shadow(0px 0px 10px rgb(115, 6, 6));
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center; 
}
#help_button:hover{
    font-size: 22px;
}

.pyramid1{
    width: 20vw;
    height: auto;
    filter: drop-shadow(0px 0px 30px rgba(0,0,0,.2));
}
#bullmoon_banner_maindiv {
    background-image: url('bg1.jpg');
    perspective: 300px;
    position: relative;
    width: 100vw;
    height: 100vh;
    background-attachment: fixed;
}
#bullmoon_banner_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Prompt,sans-serif;
    font-weight: 800;
    font-size:max(60px,5vw);
    line-height: max(50px,1vw);
    letter-spacing: 0.2vw;
    right: 0px;
    height: calc((100vh - 32vw));
    top: 0px;
    width: 100vw;
    text-align: center;
    z-index: 10000;
}

#bullmoon_banner_logoimg {
    height: 90%;
    width: auto;
    padding-top: 0vh;
}

#bullmoon_banner_mini_logo{
    width: max(40vw,300px);
    filter: drop-shadow(0px 0px 2px black);
}

#bullmoon_banner_text1 {
    font-size:8vw;
    font-weight: 500;
    letter-spacing: -0.5vw;
    filter: drop-shadow(0 0 1rem rgb(13, 102, 255));
    line-height: 10vw;
}
#bullmoon_banner_bull_out {
    max-width: 100vw;
    height: 200vh;
    top: 0px;
    overflow-x: hidden;
    position: absolute;
}
#bullmoon_banner_bull {
    width: 100vw;
    height: 100vh;
    left: 0px;
}
#banner_long{
    position: absolute;
    min-width: 90vw;
    width: 90vw;
    height:auto;
    left: calc((100vw - 90vw ) / 2);
    bottom: 100vh;
    mask-image: url('banner_long_mask2.gif');
    mask-mode: alpha;
    mask-size: 100%;
}

/************************************************/
#bullmoon_banner_div2 {
    background-image: linear-gradient(rgb(0,0,0) white);
    position: relative;
    width: 100vw;
    height: 140vh;
    padding-bottom: 10vh;
    
}
#bullmoon_banner_div2_inner1 {
    font-family: Prompt,sans-serif;
    font-weight: 400;
    line-height:max(3vw,18px);
    font-size: max(2.5vw,18px);    
}
.banner_div2_motto {
    font-size: 5vw;
    line-height: 4vw;
    letter-spacing: .3vw;
   
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 35px white);
    color: white
}
.banner_div2_motto_big1 , .banner_div2_motto_big2 , .banner_div2_motto_big3 {
    font-family: 'League Gothic', sans-serif;

    font-size: 8vw;
    line-height: 7vw;
    letter-spacing: min(1vw,2px);
    color: white;
}
.banner_div2_motto_big2 {
}
.banner_div2_motto_big3 {
}
.banner_div2_motto_plus {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: max(7vw,70px);
    line-height: max(3vw,50px);
    letter-spacing: 0vw;
}
.banner_div2_motto_small {
    font-family: 'Prompt', sans-serif;
    font-size: 1.2vw;
    line-height: 1.2vw;
    letter-spacing: .1vw;
}
#bullmoon_banner_div2_inner2 {
    position: absolute;
    color: #000;
    width: 28vw;
    
    right: 5%;
    min-height: 50vh;
    border-radius: 2vw;
    padding: 0vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin: 3vw;
    background-color: rgba(255,255,255,.4);
    /* background-color: rgba(0,0,0,.8); */
    font-family: Prompt,sans-serif;
    font-weight: 400;
    line-height:1.1vw;
    font-size: 1.2vw;
    letter-spacing: 2px;
    filter: drop-shadow(0px 0px 20px rgb(119, 126, 218));  
}
.bullmoon_banner_div2_inner2_1 {
    display: flex;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 0vh;
}
.bullmoon_banner_div2_inner2_1_bullet {
    width: 15%;
    margin-right: 2vw;
    margin-left: 4vw;
}
.bullmoon_banner_div2_inner2_1_bullet_img {
    width: 90%;
    height: auto;
    margin-right:10%;
}
.bullmoon_banner_div2_inner2_1_text , .bullmoon_banner_div2_inner2_1_text_big1  , .bullmoon_banner_div2_inner2_1_text_big2 {
    width: 70%;
    align-items: center;
    justify-content: left;
    text-align: left;
    font-size: 1vw;
    line-height: 1vw;
    letter-spacing: 0px;
}
.bullmoon_banner_div2_inner2_1_text_big1 {
    height: 30px;
    top:0px;
    color:rgb(46, 112, 193);
    font-size: 1.5vw;
    line-height: 2vw;
    letter-spacing: 0px;
    /*letter-spacing: .2vw;*/
    vertical-align:baseline;
    font-weight: 900;   
    
}
.bullmoon_banner_div2_inner2_1_text_big2  {
    font-size: 1vw;
    font-weight: 500;
    line-height:  max(1vw,16px);
    letter-spacing: .5px;
    color: #b94e07;
}
.bullmoon_banner_div2_inner2_1_text_big3  {
    font-size: 2vw;
    line-height: 2vw;
    letter-spacing: -1px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    font-style: italic;
    filter: drop-shadow(0px 0px 10px rgba(0,0,0,.7));
}
/**********************************************/
#bullmoon_banner_btn {
    width: 100vw;
    position: absolute;
    bottom: 7vh;
    justify-content: center;
    z-index: 20000000;
    filter: drop-shadow(0px 0px 15px yellow);
}
.button_1 { 
    top: 90vh;
    width: max(20vw,200px);
    backface-visibility: hidden;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background: linear-gradient(180deg,#f1e9c4 0%,#fffce2 38%,#debf4d 40%,#efebcf 100%);
    border-radius: 100px;
    border: 0.5px solid rgba(0%,0%,0%,0.2);
    border-width: 0.5px 0.5px 0.5px 0.5px;
    padding: 0vh 22px 0vh 22px;
    padding-top: 22px;
    padding-bottom: 10px;
    box-shadow: 0px 1px 1px rgba(0,0,0,0.6),0px 0px 5px rgba(255,255,255,1),0px 1px 0px 3px rgba(0,0,0,0.2),inset 0px 1px 0px rgba(255,255,255,1);
        color: #574317;
    color: #000;
    font-size: 1.3vw;
    letter-spacing: 0px;
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.button_1 > div {
    color: #999;
    font-size: 10px;
    font-family: Helvetica Neue;
    font-weight: initial;
    font-style: normal;
    text-align: center;
    margin: 0px 0px 0px 0px
}

.button_1 > i {
    font-size: 1em;
    border-radius: 0px;
    border: 0px solid transparent;
    border-width: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    position: static
}

.button_1 > .ld {
    font-size: initial
}

#vdo_banner_new {
    width: 100vw;
    height:140vh;
    /*overflow-x: hidden;*/
    position: absolute;
}
#myVideo1{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit:cover;
    object-position: top;
}
.button_explain {
    width: max-content;
    font-size: 18px;
    font-family: 'Prompt',sans-serif;
    font-weight: 300;
    color: rgb(255, 255, 255);
    padding: 10px;
    background-color: rgba(0%,0%,0%,0.6);
    border-radius: 20px;
}
#br_explain {
    display: none;
}
/**********************************************/
.bullmoon_mint_div{
    height: 100vh;
}

.bullmoon_banner_div2_cht{
    width: 50vw;
    height: 100vh;
    top:35%;
    font-family: 'digit7';
    font-size: 1.5vw;
    letter-spacing: .5vw;
    position: absolute;
    color: rgba(0,0,0,.9);
    /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
}
.bullmoon_banner_div2_cht2{
    font-family: 'digit7';
    font-size: 3vw;
    letter-spacing: .35vw;
    position: absolute;
    
    top: 102%;
    font-weight: 200;
    font-style: italic;
    color: rgba(255,255,255,.9);
    /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
}
.bullmoon_banner_div2_cht3{
    font-family: 'prompt',sans-serif;
    font-size: .9vw;
    letter-spacing: .08vw;
    position: absolute;
    
    top:131%;
    font-weight: 300;
    color: rgba(255,255,255,1);
    text-align: left;
    /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
}
.bullmoon_banner_div2_cht4{
    font-family: 'prompt',sans-serif;
}

.bullmoon_banner_div2_countdown {
    color: #000;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: max(60px , 5.5vw);
    letter-spacing: .3vw;
    line-height: max(60px , 5.5vw);
    font-family: 'digit7mono';
    padding-top: 20px;
    padding-bottom: 20px;
}
#countdownpart {
    font-size: max(1.6vw,20px);
    font-family: 'prompt',sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    
}
.digitcount {
    background-color: rgba(0, 0, 0,.4);
    color:rgb(255, 255, 255);
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    font-weight: 100;
    letter-spacing: 0px;
    
}
#bullmoon_banner_div2_inner3 {
    position: absolute;
    display: flex;
    left:5%;
    top:47%;
    font-family: 'digit7';
    font-size: 100px;
    color: rgb(255, 255, 255);
    /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
}
.bullmoon_banner_div2_inner3_t1 {
    background-color: rgba(0, 0, 0,.6);
    border-radius: 1vw;
    margin: .5vw;
    padding: 1vw;
    padding-bottom: 1.8vw;
    font-family: 'digit7mono';
    font-size: 6vw;
    line-height: 6vw;
}
.bullmoon_banner_div2_inner3_t1_colon {
    font-family: 'digit7';
    font-size: 10vw;
    line-height: 10vw;
}
.bullmoon_banner_div2_inner3_t2 {
    font-family: 'digit7';
    font-size: 1.6vw;
    letter-spacing: .1vw;
    line-height: 0vw;
    color: rgb(133, 128, 185);
}
.BB {
    font-size: 2.5vw;
    line-height: 2.5vw;
    font-weight: 900;
}
/**********************************************/
@media only screen and (max-width: 1000px) {
    .bg {}  

    .pyramid1{
        width: 80vw;
        height: auto;
        filter: drop-shadow(0px 0px 30px rgba(0,0,0,.2));
    }
    .storyDiv{
        /*top: -130vw;*/
        padding-top: 190vw;
    }
  
    #bullmoon_banner_bull {
        width: 100vw;
        left: 0px;
    }
    #bullmoon_banner_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Prompt,sans-serif;
        font-weight: 800;
        font-size:max(60px,5vw);
        line-height: max(50px,1vw);
        letter-spacing: 0.2vw;
        right: 0px;
        height: calc((100vh - 460px));
        top: 0px;
        width: 100vw;
        text-align: center;
        z-index: 10000; 
    }
    
    #bullmoon_banner_logoimg {
        height: 60%;
        width: auto;
        filter: drop-shadow(0px 0px 10px black);
        padding-top: 0vh;
    }

    #bullmoon_banner_div2 {
        height: 350vw;
    }

    #bullmoon_banner_div2_inner2 {
    }

    .banner_div2_motto_big1 , .banner_div2_motto_big2 , .banner_div2_motto_big3 {
        font-size: 10vw;
        line-height: 10vw;
        letter-spacing: .1vw;
        color: white;
    }
    .banner_div2_motto_plus {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:35px;
        line-height: 20px;
        letter-spacing: .2vw;
    }
    .banner_div2_motto_small {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0vw;
    }
    
    #bullmoon_banner_div2_inner2 {
        color: #000;
        left: 3vw;
        width: min(90vw,900px);
        border-radius: 30px;
        top:160vw;
        height: 160vw;
        font-size: 4vw;
        line-height: 7vw;
        font-weight: 400;

        background-color: rgba(255,255,255,.6);
    }
    #bullmoon_banner_div2_inner2_1{
        padding: 0vw;
        margin: 0vw;
    }
    /**********************************************/
    .bullmoon_banner_div2_cht{

        font-family: 'digit7';
        font-size: 6vw;
        letter-spacing: .65vw;
        line-height: 6vw;
        position: absolute;
        width: 100%;
        top:28vw;
        color: rgba(255, 255, 255, 17)
        /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
    }
    .bullmoon_banner_div2_cht2{
        color: white;
        font-family: 'digit7';
        font-size: 6vw;
        letter-spacing: .65vw;
        line-height: 6vw;
        position: absolute;
        left:18vw;
        top:26vw;
        /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
    }
    .bullmoon_banner_div2_cht3{
        color: white;
        font-family: 'digit7';
        font-size: 2.5vw;
        letter-spacing: .65vw;
        line-height: 6vw;
        position: absolute;
        left:0vw;
        top:30vw;
        /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
    }
    #bullmoon_banner_div2_inner3 {
        position: absolute;
        display: flex;
        left:12.5vw;
        top:35vw;
        font-family: 'digit7';
        color: rgb(255, 255, 255);
        /*filter: drop-shadow(0px 0px 20px rgb(255, 255, 255) );*/
    }
    .bullmoon_banner_div2_inner3_t1 {
        background-color: rgba(0, 0, 0,.5);
        border-radius: 3vw;
        margin: .5vw;
        padding: 3vw;
        padding-top:5vw;
        padding-bottom:3vw;
        font-family: 'digit7mono';
        font-size: 12.5vw;
        line-height: 12.5vw;
    }
    .bullmoon_banner_div2_inner3_t2 {
        font-family: 'digit7';
        font-size: 4vw;
        letter-spacing: .1vw;
        line-height: 4vw;
        color: rgb(133, 128, 185);
    }
    /**********************************************/

    #vdo_banner_new{
        width: 100vw;
        height:315vw;
        overflow-x: hidden;
        position: absolute;
        
    }
    #myVideo1{
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit:cover;
        object-position: top;
    }
    /**********************************************/
    .bullmoon_banner_div2_inner2_1_bullet_img {
        width: 90%;
        height: auto;
        margin-right:20%;
    }
    .bullmoon_banner_div2_inner2_1_text , .bullmoon_banner_div2_inner2_1_text_big1  , .bullmoon_banner_div2_inner2_1_text_big2 {
        width: 70%;
        align-items: center;
        justify-content: left;
        text-align: left;
        font-size: 10vw;
        line-height: 10vw;
        padding: 0px;
        margin: 0px;
        font-weight: 900;
    }
    .bullmoon_banner_div2_inner2_1_text_big1 {
        font-size: 10vw;
        line-height: 8.5vw;
        letter-spacing: -0vw;
        font-weight: 900;
        padding: 0px;
        margin: 0px;
    }
    .bullmoon_banner_div2_inner2_1_text_big2  {
        font-size: 4vw;
        line-height: 4vw;
        letter-spacing: 0vw;
        padding: 0px;
        margin: 0px;
        font-weight: 900;
        color: rgb(0, 0, 0);
    }
    .bullmoon_banner_div2_inner2_1_text_big3  {
        font-size: 10vw;
        line-height: 10vw;
        font-weight: 800;
        letter-spacing: -.2vw;
        padding: 0px;
        margin: 0px;
        font-style: normal;
        color: rgb(0, 0, 0);
        filter: none;
    }
    .BB {
        font-size: 9vw;
        line-height: 9vw;
        font-weight: 700;
    }
    .BB2 {
        font-size: 5vw;
        font-weight: 900;
        line-height: 5vw;
    }
    .bullmoon_banner_div2_inner2_1_text {
        font-size: 3.5vw;
        line-height: 3.5vw;
        color: rgb(84, 80, 103);
    }

    .button_1 {
        width: 80vw;
        font-size: 22px; 
        margin: 0vw;
        padding: 0vw 1vw 0vw 1vw;
        line-height: 10vw;
    }
    
    #bullmoon_banner_btn {
        filter: drop-shadow(0px 0px 1px yellow);
        
    }
    #br_explain {
        display: inline-block;
    }
    #banner_long{
        position: absolute;
        min-width: 1410px;
        width: 1410px;
        height:auto;
        bottom: 100vh;
        left: calc((100vw - 1410px)/2);
        mask-image: url('banner_long_mask2.gif');
        mask-mode: alpha;
        mask-size: 100%;
    }

    .bullmoon_banner_div2_countdown {
        color: #000;
    }
}