$primaryColor: #c7b48c;
$secondaryColor: #35054D;

html,
body {
  min-height: 100%;
  height: auto;
}

body {
  background-color: #fff;
  font-family: 'Prompt', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.page-remove-reset {
  font-size: 16px;

  p {
    margin-bottom: 1rem;
  }
}

.banner-placeholder {
  background-color: #f5f7fa;
  border-radius: 8px;
  height: 250px;
}

.course-container {
  max-width: 1070px;
}

.course-datetime {
  // background-color: #1B0339;
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  font-weight: bold;
  min-width: 170px;
  box-shadow: 0 4px 24px rgba(#666, 15%);

  @media(max-width: 767px) {
    min-width: 120px;
    padding: 10px;
  }

  h2 {
    letter-spacing: 2;
    font-size: 30px;

    @media(max-width: 767px) {
      font-size: 26px;
    }
  }
}

.course-location {
  font-size: 20px;
  // font-style: italic;
}

.course-item {
  + .course-item {
    margin-top: 20px;
  }

  .course-name {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .course-speaker {
    font-size: 14px;

    @media(max-width: 767px) {
      display: block !important;

      > div {
        margin-left: 0 !important;
      }
    }
  }

  .d-flex {
    @media(max-width: 767px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
}

.course-subject-list {
  display: flex;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;
}

.course-subject-item {
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 24px rgba(102, 102, 102, 0.15);
  padding: 2rem 3rem;
  margin-left: 15px;
  margin-right: 15px;

  @media(max-width: 576px) {
    font-size: 12px;
    padding: 10px 10px 20px;
    margin-left: 5px;
    margin-right: 5px;

    img {
      width: 80px;
    }
  }
}

.speaker-item {
  p {
    line-height: 1.5;
  }

  @media(min-width: 768px) {
    p {
      height: 54px;
    }
  }

  @media(max-width: 767px) {
    .card-body { 
      flex-direction: row;
      text-align: left !important;
      align-items: center;
    }
  }
}

.speaker-profile {
  width: 180px;
  height: 180px;
  border-radius: 50em;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: 767px) {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
}

.full-course-desc {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;

  p {
    line-height: 30px;
  }
}

.mobile-reorder {
  @media(max-width: 767px) {
    order: 3;
  }
}

.mobile-reorder1 {
  @media(max-width: 991px) {
    order: 1;
  }
}

.mobile-reorder2 {
  @media(max-width: 991px) {
    order: 2;
  }
}

.mobile-reorder3 {
  @media(max-width: 991px) {
    order: 3;
  }
}

.mobile-reorder4 {
  @media(max-width: 991px) {
    order: 4;
  }
}

.mobile-reorder5 {
  @media(max-width: 991px) {
    order: 5;
  }
}

.course-item {
  @media(max-width: 767px) {
    flex-direction: column;
  }

  .course-detail {
    @media(min-width: 768px) {
      margin-left: 20px;
    }

    @media(max-width: 767px) {
      margin-top: 20px;
    }
  }
}

.course-benefit {
  width: 120px;
  height: 120px;
  border-radius: 100rem;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $primaryColor;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .h1 {
    font-size: 50px;
    font-weight: bold;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  max-width: 100%;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.btn-register {
  padding: 3px 60px;
}

.card {
  box-shadow: 0 4px 24px rgba(#666, 15%);
  border-radius: 8px;
  border-color: #eee;
}

.btn-primary {
  background-color: $primaryColor;
  border-color: $primaryColor;

  &:hover, &:focus {
    background-color: darken($primaryColor, 5%);
    border-color: darken($primaryColor, 5%);
  }
}

.bg-light {
  background-color: #f5f7f9;
}

.bg-dark {
  background: #212429;
}

.bg-purple {
  background-color: #1B0339 !important;
}

.bg-purple-gradient {
  background: #1B0339; 
  background: -webkit-linear-gradient(135deg, #35054D, #1B0339); 
  background: linear-gradient(135deg, #35054D, #1B0339);  
}

.text-primary {
  color: $primaryColor !important;
}

.text-secondary {
  color: $secondaryColor !important;
}

.fw-semibold {
  font-weight: 600;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-15-xs {
  @media(max-width: 576px) {
    font-size: 15px !important;
  }
}