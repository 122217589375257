#roadmap_maindiv {
    width: 100vw;
    background-image: url('FAQ_background4.jpg');
    background-size: 130% 130%;
    background-position: center;
    display: flex;
    justify-content: center;
}
#roadmap_container {
    font-family: 'prompt',sans-serif;
    font-size: 10vw;
    width: min(90vw,1500px);
}
#roadmap_container_header {
    margin-top: 10vh;
    filter: drop-shadow(0px 0px 5vw blue);
}

.roadmap_circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-family: 'Prompt',sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: black;
    border-radius: 100%;
    background-color: yellow;
    padding: 0vw;
    width: 5vw;
    height: 5vw;
}
.roadmap_circle_bigtext{
    font-size: 20px;
}

/**********************************************/
@media only screen and (max-width: 1000px) {
    #roadmap_maindiv {
        background-size: cover;
        background-position: left;
        background-image: url('FAQ_background3_long.jpg');
    }
    #roadmap_container {
        font-size: 15vw;
        width: 100vw;
    }
    #roadmap_container_header {
        margin-top: 10vh;
    }
}