.bg {
    /* The image used */
    background-color: rgb(215, 111, 5);
    background-image: url('bg1.jpg');

    /* Full height */
    width: 100%;
    background-repeat: repeat-x;
    background-size:cover;
    background-attachment: fixed;
}

#bullmoon_banner2_container {
    width: 100%;
    height: 100%;
    background-image: url('/src/Main_Page/bullbg1.jpg'); 
    
    background-size:cover;
    background-position-x: left;
    background-position-y: top;
}

#bullmoon_banner2_maindiv {
    
    /*background-image: linear-gradient(-90deg ,  rgb(54, 28, 19),  rgba(54, 28, 19,.6) , rgba(205, 176, 51, 0)); */
    /*linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0)); */
    background-size:auto;
    padding-top: max(6vh,6vw);
    position: relative;
    width: 100vw;
    height: 100vh;
    
}
#bullmoon_banner2_logo {
    font-family: Prompt,sans-serif;
    font-weight: 800;
    font-size:max(45px,5vw);
    line-height: max(50px,5vw);
    letter-spacing: 0.2vw;
    
    right: 0px;
    top : min(11vw,40px);
    width: 100%;
    text-align: center;
    z-index: 100;
    color:aliceblue;
}
#bullmoon_banner_text1 {
    font-size:8vw;
    font-weight: 500;
    letter-spacing: -0.5vw;
    filter: drop-shadow(0 0 1rem rgb(13, 102, 255));
    line-height: 10vw;
}

/************************************************/
#bullmoon_banner2_div2 {
   
    display: flex;
    position: relative;
    width: 100vw;
    height: 52vw;
    align-items: center;
    justify-content: right;
    padding-bottom: 10vh;
    margin: 0px;
}

.banner2_div2_motto {
    font-size: 5vw;
    line-height: 4vw;
    letter-spacing: .3vw;
   
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 40px white);
}
.banner2_div2_motto_big1 , .banner2_div2_motto_big2 , .banner2_div2_motto_big3 {
    font-family: 'League Gothic', sans-serif;

    font-size: max(10vw,110px);
    line-height: max(9vw,110px);
    letter-spacing: 0px;
}
.banner2_div2_motto_big2 {
}
.banner2_div2_motto_big3 {
}
.banner2_div2_motto_plus {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: max(7vw,70px);
    line-height: max(3vw,50px);
    letter-spacing: .2vw;
}
.banner2_div2_motto_small {
    font-family: 'Prompt', sans-serif;
    font-size: max(1vw,22px);
    line-height: max(1vw,22px);
    letter-spacing: .1vw;
}


#bullmoon_banner2_div2_inner1 {
    max-width: 45vw;
    position: absolute;
    right: 0px;
    top:0px;
    color: #000;
    
    min-height: 50vh;
    border-radius: max(2vw,20px);
    padding: 0vw;
    padding-top: 0vh;
    padding-bottom: 5vh;
    margin: 2vw;
    
    /* background-color: rgba(0,0,0,.8); */
    font-family: Prompt,sans-serif;
    font-weight: 600;
    line-height:max(1.6vw,18px);
    font-size: max(1.6vw,18px);  
    
}
.bullmoon_banner2_div2_inner2_1 {
    display: flex;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 0vh;
}
.bullmoon_banner2_div2_inner2_1_bullet {
    width: min(10vw,50px);
    
    margin-left: 4vw;
    display: flex;
    justify-content: right;
}
.bullmoon_banner2_div2_inner2_1_bullet_img {
    width: 85%;
    height: auto;
    margin-right:20%;
}
.bullmoon_banner2_div2_inner2_1_text , .bullmoon_banner2_div2_inner2_1_text_big1  , .bullmoon_banner2_div2_inner2_1_text_big2 {
    width: 85%;
    align-items: center;
    justify-content: left;
    text-align: left;
    font-size: max(1vw,12px);
    padding-right: 4%;
    
}
.bullmoon_banner2_div2_inner2_1_text_big1  {
    color: aliceblue;
    font-size: 2.2vh;
    font-weight: 400;   
    text-align: right;
    filter: drop-shadow(0px 0px 8px black);
}
.bullmoon_banner2_div2_inner2_1_text_big2  {
    font-size: max(1.2vw,15px);
    color: yellow;
    letter-spacing: -.5px;
    font-weight: 300;
}
.bullmoon_banner2_div2_inner2_1_text_big3  {
    font-size: max(2.7vw,34px);
    line-height: max(2.9vw,40px);
    letter-spacing: -1px;
    font-weight: 200;
    color: rgb(88, 136, 136);
    font-style: italic;
    
}

@media only screen and (max-width: 1000px) {
    .bg {
    }    
    
    #bullmoon_banner2_container {
        background-position-x:right;
    }
    #bullmoon_banner2_div2_inner1 {
        max-width: 100vw;
        
    }
    #bullmoon_banner2_div2 {
        height: 70vh;
    }
    #bullmoon_banner2_maindiv {
        background-image: none;
        height: 70vh;
    }
    .bullmoon_banner2_div2_inner2_1_text_big1  {
        color: aliceblue;
        font-size: 2.2vh;
        font-weight: 400;   
        text-align: right;
        filter: drop-shadow(0px 0px 8px black);
    }
    .bullmoon_banner2_div2_inner2_1 {
        padding-top: 6%;
    }
}