#help_essentialDiv {
  /* Full height */
  z-index : 4000;
  align-items: center;
  align-content: center;
  justify-content: center;
  
  width: 100vw;
  height: auto;
  display:flex;
  flex-wrap:wrap;
  /* Center and scale the image nicely */
  font-family: 'Space Grotesk', sans-serif;
  font-family: Montserrat;
  
  background-color: rgb(0, 0, 0);
}
.video-background {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.v2 {
  position: absolute;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 2500px;
  z-index: 1000000;
}

.content1 {
  width: 100vw;
  height: 100%;
  top: 30px;
  position: fixed;
  overflow-y: scroll;
  z-index: 2000000;
}

#exclusive_back_div {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
  font-family: 'Space Grotesk', sans-serif;
  color:white;
  font-size: 18px;
  background-color: transparent;
  z-index: 2000002;
}

.button_2 { 
  top: 90vh;
  width: max(20vw,200px);
  backface-visibility: hidden;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: linear-gradient(180deg,#f1e9c4 0%,#fffce2 38%,#debf4d 40%,#efebcf 100%);
  border-radius: 100px;
  border: 0.5px solid rgba(0%,0%,0%,0.2);
  border-width: 0.5px 0.5px 0.5px 0.5px;
  padding: 0vh 22px 0vh 22px;
  padding-top: 22px;
  padding-bottom: 10px;
  box-shadow: 0px 1px 1px rgba(0,0,0,0.6),0px 0px 5px rgba(255,255,255,1),0px 1px 0px 3px rgba(0,0,0,0.2),inset 0px 1px 0px rgba(255,255,255,1);
      color: #574317;
  color: #000;
  font-size: 1.3vw;
  letter-spacing: 0px;
  font-family: 'Prompt', sans-serif;
  font-weight: 600;
  font-style: normal;
}

@media only screen and (max-width: 1000px) {
  #help_back_div {
    background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(0,0,0,1) 73%);
  }
} 
